<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="app-share" slot="component">
      <div>
        <p class="app-share-link-title">{{$t('share this app with friends')}}</p>
        <!-- <p class="app-share-app-title">Check out "{{ app.title }}" on {{ projectName }}!</p> -->
        <div class="app-share-input">
          <input
            class="app-share-input-link-field"
            id="app-share-link"
            type="text"
            @keydown.prevent
            v-model="link"
            ref="link"
          />
          <button
            class="app-share-input-btn-copy copy-btn"
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
            :data-clipboard-text="link"
          >Copy</button>
        </div>
      </div>
      <p class="app-share-title">{{$t('share to')}}</p>
      <div>
        <ul class="app-share-list">
          <li>
            <img
              src="@/assets/icons/newfb.svg"
              class="share-ico"
              alt="fb icon"
              @click="shareThroughFacebook"
            />
          </li>
          <li>
            <a target="_blank" :href="twitterShareUrl" id="twitter-button">
              <img
                src="@/assets/icons/newtwitter.svg"
                @click="twitterShare"
                class="share-ico"
                alt="gp icon"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Popup>
</template>

<script>
import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import ClipboardJS from "clipboard";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import common from "@/mixins/common.js";
import { _providerId } from "@/provider-config.js";

import { showSnackBar } from '@/utilities.js';

export default {
  props: {
    closePopup: {
      type: Function
    }
  },
  data() {
    return {
      width: "40%",
      margin: "15% auto",
      radiusType: "full",
      medium: null,
      link: null,
      twitterlink: null,
      facebooklink: null,
      deeplink: null,
      clipboard: undefined,
      twitterShareUrl: null,
      projectName: null,
      pricemodel: null,
      availabilities: [],
      providerUniqueId: _providerId
    };
  },
  watch: {
    twitterlink(val) {
      if (val) {
        this.sendToTwitter();
      }
    },
    facebooklink(val) {
      if (val) {
        this.sendToFacebook();
      }
    }
  },
  created() {
    this.createLink();

    this.initTwitterUrl();
    window.dispatchEvent(window.facebookLoaded);
  },
  mounted() {
    setTimeout(() => {
      this.$refs.link.disabled = true;
      let dom = document.getElementById("popup-close-icon");
      dom.style.opacity = 1;
    }, 100);
  },
  methods: {
    createBranchLink(medium) {
      let self = this;
      let linkData = {
        campaign: "App Share",
        channel: medium,
        stage: "new user",
        alias: "",
        data: {
          $og_title:
            "Hey, check out this app To get unlimited entertainmentApp",
          custom_bool: true,
          custom_int: Date.now()
        }
      };

      branch.link(linkData, function(err, link) {
        // console.log("linkData", link);
        if (medium === "sharing") {
          self.link = link;
        } else if (medium === "twitter") {
          self.twitterlink = link;
        } else if (medium === "facebook") {
          self.facebooklink = link;
        }
      });
    },
    createLink() {
      this.createBranchLink("sharing");
      let self = this;

      if (this.clipboard) {
        this.clipboard.destroy();
      }

      // this.deeplink = `${window.location.host}/content?contentid=${this.content.objectid}`;

      this.clipboard = new ClipboardJS(".copy-btn");
      this.clipboard.on("success", function(e) {
        // Show the snackbar message
        // snackbar.show({ text: "Link Copied", pos: "bottom-center" });
        showSnackBar(this.$t("Link Copied"));
        e.clearSelection();
      });
      this.clipboard.on("error", function(e) {
        console.error("Action:", e.action);
        console.error("Trigger:", e.trigger);
      });
    },
    twitterShare() {
      console.log("kjhghj");
    },
    initTwitterUrl() {
      this.createBranchLink("twitter");
    },
    sendToTwitter() {
      console.log("twitter link", this.twitterlink);
      this.twitterShareUrl =
        "https://twitter.com/share?url=" + decodeURIComponent(this.twitterlink);
    },
    shareThroughFacebook() {
      // Get the URL to be shared
      this.createBranchLink("facebook");
    },
    sendToFacebook() {
      let link = this.facebooklink;

      // Get the title to be shown on the feed
      let title = `Hey, check out this app To get unlimited entertainmentApp`;

      // Initialize Share
      window.FB.ui(
        {
          method: "share_open_graph",
          action_type: "og.shares",
          action_properties: JSON.stringify({
            object: {
              "og:url": link,
              "og:title": title
            }
          })
        },
        response => {
          console.log("[shareFbCB response]", response);
        }
      );
    }
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue")
  },
  mixins: [googleAnalytics, cleverTap, common]
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.app-share {
  background: $clr-dark-gd4;
  border-radius: 0.6rem;
  padding: 3rem;
  &-link-title {
    font-family: $font-regular;
    font-weight: bold;
    color: $clr-light-gd2;
    margin-bottom: 10px;
  }
  &-app-title {
    font-family: $font-regular;
    font-size: 14px;
    color: grey;
  }
  &-input {
    position: relative;
    &-link-field {
      border: 1px solid $clr-light-gd4;
      border-radius: 5px;
      color: $clr-light-gd4;
      padding-right: 20%;
      &:focus {
        border: 1px solid $clr-light-gd4;
        border-radius: 5px;
        color: $clr-light-gd4;
        background-color: transparent;
      }
    }
    &-btn-copy {
      position: absolute;
      top: 13px;
      right: 13px;
      background-color: $btn-clr;
      padding: 5px 15px;
      color: $clr-dark-gd3;
      border: none;
      border-radius: 5px;
      font-family: $font-regular;
      font-weight: bold;
      cursor: pointer;
    }
  }
  &-title {
    color: grey;
    margin: 10px 0;
    font-family: $font-regular;
  }
  &-list {
    display: flex;
    height: 45px;
    li {
      margin-right: 15px;
      border-radius: 5px;
      cursor: pointer;
      &:last-child {
        margin-top: 3px;
      }
      img {
        width: 45px;
        // border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 10px;
      }
      &:hover {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .app-share {
    padding: 2rem;
    &-input {
      &-link-field {
        padding-right: 35%;
      }
    }
  }
}
</style>